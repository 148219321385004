$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

.post-full__description-table, .table, table {
  margin-bottom: 0 !important;
}

// Legacy Content Wrap
body.FixedNav.AceOnly #ACEBodyWrap {
  margin-top: -20px !important;
  padding-top: 1% !important;
}
#ACEBodyWrap, #popcontainer {

	#ACEBreadCrumbWrap {
		display: none !important;
	}

	margin-top: 0 !important;
	padding-top: 1% !important;
	background: #fff !important;

	*, *:before, *:after {
		-webkit-box-sizing: content-box !important;
		-moz-box-sizing: content-box !important;
		box-sizing: content-box !important;
		font-family: Muli,sans-serif !important;
	}

	.vjs-control.vjs-menu-button,
	.vjs-control.vjs-button {
		&::before,
		&::after {
			font-family: azuremediaplayer !important;
		}
	}

	.mce-tinymce.mce-container.mce-panel .mce-ico:before, .mce-tinymce.mce-container.mce-panel .mce-ico:after, .mce-tinymce.mce-container.mce-panel .mce-ico {
		font-family: 'tinymce',Arial !important;
	}

	.h1, .h2, .h3, h1, h2, h3 {
		margin-top: 0 !important;
		margin-bottom: 1% !important;
		text-transform: none !important;
	}



	.GrayNB12BTN {
		transition-property: color,background-color,border-color,fill !important;
		transition-duration: .3s !important;
		padding: 15px 20px !important;
		display: inline-block;
		border: 1px solid #053c5d !important;
		font-weight: 500 !important;
		font-family: Roboto,sans-serif !important;
		text-align: center !important;
		text-transform: uppercase !important;
		letter-spacing: 1px !important;
		line-height: 1.14286 !important;
		-webkit-appearance: none !important;
		background-color: #053c5d;
		box-shadow: none !important;
		background-image: none !important;

		:hover {
			color: #fff;
			background-color: #053c5d;
			border-color: #053c5d
		}
	}

	#ACEPaperWrap, #ACEBodyInterWrap {
		max-width: 1350px !important;
	}

	#ACEBreadCrumbWrap {
		background: #fff !important;
	}

	#MyACEHeaderWrap, #WhoAmIWrap {
		background-color: #02263c !important;
	}

	#MyACELeftCol {

		.FitCertCoreNav a.h5, .FitCertCoreNav span.h5 {
			text-transform: none !important;
			font-weight: normal !important;
			border-bottom: 1px solid #ddd !important;
		}

		.FitCertCoreNav a {
			text-transform: none !important;
			font-weight: normal !important;
			color: #02263c !important;
		}

		.FitCertCoreNav a:hover {
			text-decoration: underline !important;
		}

		div.BoxMe {
			background-color: #fff !important;
		}
	}

	#MyACERightCol {
		@media screen and (min-width: 1000px) {
			width: 68% !important;
		}

		h1.LargeH1 {
			font-size: 2em !important;
		}

		h2.BlueH2 {
			color: #02263c !important;
			border-bottom: 1px solid #ddd !important;
		}

		h3.SmallLink a {
			color: #02263c !important;
		}

		.CertDisplay h5 {
			margin-bottom: 0 !important;
		}
	}
	//Exam Registration Process
	#HexRightCol.ExamRegWizard {
		padding: 3% !important;
		width: 90% !important;

		#ShowSteps {
			float: left;
			width: 99%;
			margin: 1% 0 2% 1% !important;
			list-style-type: decimal;
			list-style-position: outside !important;
			display: block;

			@media (max-width: $screen-xs) {
				display: none !important;
			}

			li {
				float: left;
				color: #bbb;
				height: 14px;
				width: auto !important;
				clear: none !important;
				border-right: 1px solid #aaa;
				margin: 0 2% 0 1% !important;
				padding: 0 1% 0 0 !important;
				list-style-type: decimal !important;
			}
		}

		#ShowSteps li.Six {
			margin-right: 0px !important;
			padding-right: 0px !important;
			border-right: none !important
		}

		#ShowSteps.Step2 li.One, #ShowSteps.Step3 li.One, #ShowSteps.Step3 li.Two, #ShowSteps.Step4 li.One, #ShowSteps.Step4 li.Two, #ShowSteps.Step4 li.Three {
			color: #666;
		}

		#ShowSteps.Step1 li.One, #ShowSteps.Step2 li.Two, #ShowSteps.Step3 li.Three, #ShowSteps.Step4 li.Four {
			color: #e50005;
			font-weight: bold
		}

		h2 {
			border: none !important;
		}

		#datepicker.hasDatepicker {
			float: left !important;
		}

		#CBTLocationTimeWrap {
			float: left !important;
		}

		#CBTExamVenueControl {
			label {
				text-align: left !important;
			}

			.mapspan {
				padding-right: 1%;
			}

			.sitedetails {
				padding-left: 1%;
			}
		}

		.rblGenderSelect label {
			text-align: left !important;
			width: auto !important;
		}
	}
}

.MyACENewLook #ACEBodyInterWrap {
  background: none !important;
}

#MyACEHeaderWrap span.h2 img {
  vertical-align: unset !important;
}

//ACE Store
#AddToCartWrap{
    font-size: .82em !important;

    h4 {
        text-transform: none !important;
    }
}
#ACEStore12 {
  #StoreHeader {
    box-shadow: none !important;
    margin-bottom: 1% !important;
  }

  #CheckOutLeftCol h1 {
    color: #02263c !important;
    border-bottom: 1px solid #02263c !important;
  }

  #CheckOutLeftCol #ShowSteps li {
    @media (min-width: 750px) {
      white-space: nowrap;
    }
  }

  #ShowSteps {
    margin-top: 1% !important;
  }

  label {
    margin-bottom: 0 !important;
  }

  p {
    line-height: 1.5em !important;
  }

  h1, h2, h3, span.h2 {
    padding: 0 0 1% 0 !important;
    margin-bottom: 2% !important;
    color: #02263c !important;
    border-bottom: 1px solid #02263c !important;
  }

  h2 {
    font-size: 1.4em !important;
  }

  h2.Red {
    color: #cd333c !important;
    border-bottom: none !important;
  }

  h3 {
    font-size: 1.2em !important;
    font-weight: 500 !important;
    border-bottom: 1px solid #ddd !important;
  }

  .Store12LeftNav ul ul.hidden {
    display: block !important;
  }

  .Store12LeftNav ul ul li a {
    color: #02263c !important;
    font-size: 1em !important;
  }

  .Store12LeftNav ul ul li {
    padding: 1% 0 !important;
  }

  #Store12LeftCol .h5, #Store12LeftCol h5 {
    font-size: 1em !important;
  }

  #Store12LeftCol div.Store12LeftNav {
    box-shadow: unset !important;
  }

  div.BigError {
    max-width: 92% !important;
  }

  .ShippingWrap, .PaymentWrap, .SkinnyCart, .AgreementWrap, .AgreementReviewWrap .Agreement, .LoginBlock {
    box-shadow: none !important;
  }
}

.RedNB12BTN {
    transition-property: color,background-color,border-color,fill !important;
    transition-duration: .3s !important;
    padding: 15px 20px !important;
    display: inline-block;
    border: 1px solid #e53d46 !important;
    font-weight: 500 !important;
    font-family: Roboto,sans-serif !important;
    text-align: center !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    line-height: 1.14286 !important;
    -webkit-appearance: none !important;
    background-color: #e53d46;
    box-shadow: none !important;
    background-image: none !important;

    :hover {
      color: #fff;
      background-color: #cd333c;
      border-color: #cd333c;
      text-decoration: none;
      outline: none;
    }
  }

h1.TG20{
    font-size: 2em !important;
}

h2.TG18{
    font-size: 1.5em !important;
}
