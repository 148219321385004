.post-full__description-table, .table, table {
  margin-bottom: 0 !important;
}

body.FixedNav.AceOnly #ACEBodyWrap {
  margin-top: -20px !important;
  padding-top: 1% !important;
}

#ACEBodyWrap, #popcontainer {
  margin-top: 0 !important;
  padding-top: 1% !important;
  background: #fff !important;
}

#ACEBodyWrap #ACEBreadCrumbWrap, #popcontainer #ACEBreadCrumbWrap {
  display: none !important;
}

#ACEBodyWrap *, #ACEBodyWrap *:before, #ACEBodyWrap *:after, #popcontainer *, #popcontainer *:before, #popcontainer *:after {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  font-family: Muli, sans-serif !important;
}

#ACEBodyWrap .vjs-control.vjs-menu-button::before, #ACEBodyWrap .vjs-control.vjs-menu-button::after, #ACEBodyWrap .vjs-control.vjs-button::before, #ACEBodyWrap .vjs-control.vjs-button::after, #popcontainer .vjs-control.vjs-menu-button::before, #popcontainer .vjs-control.vjs-menu-button::after, #popcontainer .vjs-control.vjs-button::before, #popcontainer .vjs-control.vjs-button::after {
  font-family: azuremediaplayer !important;
}

#ACEBodyWrap .mce-tinymce.mce-container.mce-panel .mce-ico:before, #ACEBodyWrap .mce-tinymce.mce-container.mce-panel .mce-ico:after, #ACEBodyWrap .mce-tinymce.mce-container.mce-panel .mce-ico, #popcontainer .mce-tinymce.mce-container.mce-panel .mce-ico:before, #popcontainer .mce-tinymce.mce-container.mce-panel .mce-ico:after, #popcontainer .mce-tinymce.mce-container.mce-panel .mce-ico {
  font-family: "tinymce", Arial !important;
}

#ACEBodyWrap .h1, #ACEBodyWrap .h2, #ACEBodyWrap .h3, #ACEBodyWrap h1, #ACEBodyWrap h2, #ACEBodyWrap h3, #popcontainer .h1, #popcontainer .h2, #popcontainer .h3, #popcontainer h1, #popcontainer h2, #popcontainer h3 {
  margin-top: 0 !important;
  margin-bottom: 1% !important;
  text-transform: none !important;
}

#ACEBodyWrap .GrayNB12BTN, #popcontainer .GrayNB12BTN {
  transition-property: color, background-color, border-color, fill !important;
  transition-duration: 0.3s !important;
  padding: 15px 20px !important;
  display: inline-block;
  border: 1px solid #053c5d !important;
  font-weight: 500 !important;
  font-family: Roboto, sans-serif !important;
  text-align: center !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  line-height: 1.14286 !important;
  -webkit-appearance: none !important;
  background-color: #053c5d;
  box-shadow: none !important;
  background-image: none !important;
}

#ACEBodyWrap .GrayNB12BTN :hover, #popcontainer .GrayNB12BTN :hover {
  color: #fff;
  background-color: #053c5d;
  border-color: #053c5d;
}

#ACEBodyWrap #ACEPaperWrap, #ACEBodyWrap #ACEBodyInterWrap, #popcontainer #ACEPaperWrap, #popcontainer #ACEBodyInterWrap {
  max-width: 1350px !important;
}

#ACEBodyWrap #ACEBreadCrumbWrap, #popcontainer #ACEBreadCrumbWrap {
  background: #fff !important;
}

#ACEBodyWrap #MyACEHeaderWrap, #ACEBodyWrap #WhoAmIWrap, #popcontainer #MyACEHeaderWrap, #popcontainer #WhoAmIWrap {
  background-color: #02263c !important;
}

#ACEBodyWrap #MyACELeftCol .FitCertCoreNav a.h5, #ACEBodyWrap #MyACELeftCol .FitCertCoreNav span.h5, #popcontainer #MyACELeftCol .FitCertCoreNav a.h5, #popcontainer #MyACELeftCol .FitCertCoreNav span.h5 {
  text-transform: none !important;
  font-weight: normal !important;
  border-bottom: 1px solid #ddd !important;
}

#ACEBodyWrap #MyACELeftCol .FitCertCoreNav a, #popcontainer #MyACELeftCol .FitCertCoreNav a {
  text-transform: none !important;
  font-weight: normal !important;
  color: #02263c !important;
}

#ACEBodyWrap #MyACELeftCol .FitCertCoreNav a:hover, #popcontainer #MyACELeftCol .FitCertCoreNav a:hover {
  text-decoration: underline !important;
}

#ACEBodyWrap #MyACELeftCol div.BoxMe, #popcontainer #MyACELeftCol div.BoxMe {
  background-color: #fff !important;
}

@media screen and (min-width: 1000px) {
  #ACEBodyWrap #MyACERightCol, #popcontainer #MyACERightCol {
    width: 68% !important;
  }
}
#ACEBodyWrap #MyACERightCol h1.LargeH1, #popcontainer #MyACERightCol h1.LargeH1 {
  font-size: 2em !important;
}

#ACEBodyWrap #MyACERightCol h2.BlueH2, #popcontainer #MyACERightCol h2.BlueH2 {
  color: #02263c !important;
  border-bottom: 1px solid #ddd !important;
}

#ACEBodyWrap #MyACERightCol h3.SmallLink a, #popcontainer #MyACERightCol h3.SmallLink a {
  color: #02263c !important;
}

#ACEBodyWrap #MyACERightCol .CertDisplay h5, #popcontainer #MyACERightCol .CertDisplay h5 {
  margin-bottom: 0 !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard, #popcontainer #HexRightCol.ExamRegWizard {
  padding: 3% !important;
  width: 90% !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps {
  float: left;
  width: 99%;
  margin: 1% 0 2% 1% !important;
  list-style-type: decimal;
  list-style-position: outside !important;
  display: block;
}

@media (max-width: 480px) {
  #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps {
    display: none !important;
  }
}
#ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps li, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps li {
  float: left;
  color: #bbb;
  height: 14px;
  width: auto !important;
  clear: none !important;
  border-right: 1px solid #aaa;
  margin: 0 2% 0 1% !important;
  padding: 0 1% 0 0 !important;
  list-style-type: decimal !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps li.Six, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps li.Six {
  margin-right: 0px !important;
  padding-right: 0px !important;
  border-right: none !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step2 li.One, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step3 li.One, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step3 li.Two, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.One, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.Two, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.Three, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step2 li.One, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step3 li.One, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step3 li.Two, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.One, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.Two, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.Three {
  color: #666;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step1 li.One, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step2 li.Two, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step3 li.Three, #ACEBodyWrap #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.Four, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step1 li.One, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step2 li.Two, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step3 li.Three, #popcontainer #HexRightCol.ExamRegWizard #ShowSteps.Step4 li.Four {
  color: #e50005;
  font-weight: bold;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard h2, #popcontainer #HexRightCol.ExamRegWizard h2 {
  border: none !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #datepicker.hasDatepicker, #popcontainer #HexRightCol.ExamRegWizard #datepicker.hasDatepicker {
  float: left !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #CBTLocationTimeWrap, #popcontainer #HexRightCol.ExamRegWizard #CBTLocationTimeWrap {
  float: left !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #CBTExamVenueControl label, #popcontainer #HexRightCol.ExamRegWizard #CBTExamVenueControl label {
  text-align: left !important;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #CBTExamVenueControl .mapspan, #popcontainer #HexRightCol.ExamRegWizard #CBTExamVenueControl .mapspan {
  padding-right: 1%;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard #CBTExamVenueControl .sitedetails, #popcontainer #HexRightCol.ExamRegWizard #CBTExamVenueControl .sitedetails {
  padding-left: 1%;
}

#ACEBodyWrap #HexRightCol.ExamRegWizard .rblGenderSelect label, #popcontainer #HexRightCol.ExamRegWizard .rblGenderSelect label {
  text-align: left !important;
  width: auto !important;
}

.MyACENewLook #ACEBodyInterWrap {
  background: none !important;
}

#MyACEHeaderWrap span.h2 img {
  vertical-align: unset !important;
}

#AddToCartWrap {
  font-size: 0.82em !important;
}

#AddToCartWrap h4 {
  text-transform: none !important;
}

#ACEStore12 #StoreHeader {
  box-shadow: none !important;
  margin-bottom: 1% !important;
}

#ACEStore12 #CheckOutLeftCol h1 {
  color: #02263c !important;
  border-bottom: 1px solid #02263c !important;
}

@media (min-width: 750px) {
  #ACEStore12 #CheckOutLeftCol #ShowSteps li {
    white-space: nowrap;
  }
}
#ACEStore12 #ShowSteps {
  margin-top: 1% !important;
}

#ACEStore12 label {
  margin-bottom: 0 !important;
}

#ACEStore12 p {
  line-height: 1.5em !important;
}

#ACEStore12 h1, #ACEStore12 h2, #ACEStore12 h3, #ACEStore12 span.h2 {
  padding: 0 0 1% 0 !important;
  margin-bottom: 2% !important;
  color: #02263c !important;
  border-bottom: 1px solid #02263c !important;
}

#ACEStore12 h2 {
  font-size: 1.4em !important;
}

#ACEStore12 h2.Red {
  color: #cd333c !important;
  border-bottom: none !important;
}

#ACEStore12 h3 {
  font-size: 1.2em !important;
  font-weight: 500 !important;
  border-bottom: 1px solid #ddd !important;
}

#ACEStore12 .Store12LeftNav ul ul.hidden {
  display: block !important;
}

#ACEStore12 .Store12LeftNav ul ul li a {
  color: #02263c !important;
  font-size: 1em !important;
}

#ACEStore12 .Store12LeftNav ul ul li {
  padding: 1% 0 !important;
}

#ACEStore12 #Store12LeftCol .h5, #ACEStore12 #Store12LeftCol h5 {
  font-size: 1em !important;
}

#ACEStore12 #Store12LeftCol div.Store12LeftNav {
  box-shadow: unset !important;
}

#ACEStore12 div.BigError {
  max-width: 92% !important;
}

#ACEStore12 .ShippingWrap, #ACEStore12 .PaymentWrap, #ACEStore12 .SkinnyCart, #ACEStore12 .AgreementWrap, #ACEStore12 .AgreementReviewWrap .Agreement, #ACEStore12 .LoginBlock {
  box-shadow: none !important;
}

.RedNB12BTN {
  transition-property: color, background-color, border-color, fill !important;
  transition-duration: 0.3s !important;
  padding: 15px 20px !important;
  display: inline-block;
  border: 1px solid #e53d46 !important;
  font-weight: 500 !important;
  font-family: Roboto, sans-serif !important;
  text-align: center !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  line-height: 1.14286 !important;
  -webkit-appearance: none !important;
  background-color: #e53d46;
  box-shadow: none !important;
  background-image: none !important;
}

.RedNB12BTN :hover {
  color: #fff;
  background-color: #cd333c;
  border-color: #cd333c;
  text-decoration: none;
  outline: none;
}

h1.TG20 {
  font-size: 2em !important;
}

h2.TG18 {
  font-size: 1.5em !important;
}